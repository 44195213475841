import api from '../services/api'
import Helper from './helper'

class UserService {
  getUserBoard(p, s) {
    return api.get(`/u?${Helper.getPageQuery(p, s)}`)
  }

  createUser(row) {
    return api.post('/u', row)
  }

  updateUser(row) {
    return api.put(`/u/${row.id}`, row)
  }

  deleteUser(row) {
    return api.delete(`/u/${row.id}`)
  }
}

export default new UserService()
