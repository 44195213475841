<template>
  <div>
    <vxe-toolbar>
      <template v-slot:buttons>
        <vxe-button icon="fa fa-plus" @click="insertEvent()">新增</vxe-button>
      </template>
    </vxe-toolbar>

    <vxe-table
      border
      resizable
      row-key
      show-overflow
      highlight-hover-row
      ref="xTable"
      :data="tableData.users"
      :loading="loading"
    >
      <vxe-table-column type="seq" width="60"></vxe-table-column>
      <vxe-table-column field="name" title="姓名"></vxe-table-column>
      <vxe-table-column
        field="sex"
        title="性别"
        :formatter="formatterSex"
      ></vxe-table-column>
      <vxe-table-column field="identity" title="身份证"></vxe-table-column>
      <vxe-table-column
        field="mobile"
        title="手机号"
        show-overflow
      ></vxe-table-column>
      <vxe-table-column
        field="status"
        title="状态"
        :formatter="formatterStatus"
      ></vxe-table-column>
      <vxe-table-column field="roles" title="角色"></vxe-table-column>
      <vxe-table-column field="wecharHeadImage" title="头像">
        <template v-slot="{ row }">
          <b-img
            v-bind:src="row.wecharHeadImage"
            style="width: 40px"
            rounded="circle"
          />
        </template>
      </vxe-table-column>
      <vxe-table-column
        field="created"
        title="创建时间"
        formatter="dateTime"
      ></vxe-table-column>
      <vxe-table-column title="操作" width="100" show-overflow>
        <template v-slot="{ row }">
          <vxe-button
            type="text"
            icon="fa fa-edit"
            @click="editEvent(row)"
          ></vxe-button>
          <vxe-button
            type="text"
            icon="fa fa-trash-o"
            @click="removeEvent(row)"
          ></vxe-button>
        </template>
      </vxe-table-column>
    </vxe-table>

    <vxe-pager
      perfect
      :current-page.sync="tableData.page"
      :page-size.sync="tableData.pageSize"
      :total="tableData.totalResult"
      @page-change="pageChange"
    >
    </vxe-pager>

    <vxe-modal
      v-model="showEdit"
      :title="selectRow ? '编辑&保存' : '新增&保存'"
      width="800"
      min-width="600"
      min-height="300"
      :loading="submitLoading"
      resize
      destroy-on-close
    >
      <template v-slot>
        <vxe-form
          :data="formData"
          :items="formItems"
          :rules="formRules"
          title-align="right"
          title-width="100"
          @submit="submitEvent"
        ></vxe-form>
      </template>
    </vxe-modal>
  </div>
</template>

<script>
import User from '../models/user'
import UserService from '../services/user.service'
import RoleService from '../services/role.service'

export default {
  data() {
    return {
      loading: false,
      submitLoading: false,
      tableData: {},
      selectRow: null,
      showEdit: false,
      sexList: [
        { label: '未知', value: 0 },
        { label: '男', value: 1 },
        { label: '女', value: 2 }
      ],
      statusList: [
        { label: '已注册未启用', value: 0 },
        { label: '已启用', value: 1 },
        { label: '已禁用', value: 2 }
      ],
      roleList: [],
      formData: {
        id: null,
        name: null,
        sex: null,
        identity: null,
        mobile: null,
        status: null,
        roles: null
      },
      formRules: {
        name: [
          { required: true, message: '请输入名称' },
          { min: 1, max: 10, message: '长度在 1 到 10 个字符' }
        ],
        sex: [{ required: true, message: '请选择性别' }],
        status: [{ required: true, message: '请选择状态' }],
        identity: [
          { required: true, min: 18, max: 18, message: '请输入18位身份证' }
        ],
        mobile: [
          { required: true, min: 11, max: 11, message: '请输入11位手机号' }
        ],
        roles: [
          {
            required: true,
            message: '请选择一个角色',
            validator: this.checkRoles
          }
        ]
      },
      formItems: [
        {
          field: 'name',
          title: 'Name',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '请输入名称' } }
        },
        {
          field: 'sex',
          title: 'Sex',
          span: 12,
          itemRender: { name: '$select', options: [] }
        },
        {
          field: 'status',
          title: '状态',
          span: 12,
          itemRender: { name: '$select', options: [] }
        },
        {
          field: 'identity',
          title: '身份证',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '请输入身份证' } }
        },
        {
          field: 'mobile',
          title: '手机号',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '请输入手机号' } }
        },
        {
          field: 'roles',
          title: '角色',
          span: 12,
          itemRender: { name: '$select', options: [] }
        },
        {
          align: 'center',
          span: 24,
          titleAlign: 'left',
          itemRender: {
            name: '$buttons',
            children: [
              { props: { type: 'submit', content: '提交', status: 'primary' } }
            ]
          }
        }
      ]
    }
  },
  async created() {
    this.formItems[1].itemRender.options = this.sexList
    this.formItems[2].itemRender.options = this.statusList
    await this.loadRoles()
    this.formItems[5].itemRender.options = this.roleList
  },
  async mounted() {
    this.loading = true
    try {
      const response = await UserService.getUserBoard()
      this.tableData = response.data
    } catch {
      this.tableData = {}
    }
    this.loading = false
  },
  methods: {
    formatterSex({ cellValue }) {
      const item = this.sexList.find((item) => item.value === cellValue)
      return item ? item.label : ''
    },
    formatterStatus({ cellValue }) {
      const item = this.statusList.find((item) => item.value === cellValue)
      return item ? item.label : ''
    },
    async insertEvent() {
      this.formData = {
        name: '',
        sex: '',
        identity: '',
        mobile: '',
        status: '',
        roles: ''
      }
      this.formItems[5].itemRender.enabled = true
      this.selectRow = null
      this.showEdit = true
    },
    async editEvent(row) {
      this.formData = {
        id: row.id,
        name: row.name,
        sex: row.sex,
        identity: row.identity,
        mobile: row.mobile,
        status: row.status,
        roles: row.roles
      }
      this.formItems[5].itemRender.enabled = !row.roleLock
      this.selectRow = row
      this.showEdit = true
    },
    removeEvent(row) {
      this.$XModal.confirm('您确定要删除该数据?').then(async (type) => {
        if (type === 'confirm') {
          this.loading = true
          const user = new User()
          Object.assign(user, row)
          try {
            await UserService.deleteUser(user)
            this.showEdit = false
            const response = await UserService.getUserBoard(
              this.tableData.page,
              this.tableData.pageSize
            )
            this.tableData = response.data
            // this.$refs.xTable.remove(row)
          } catch {}
          this.loading = false
        }
      })
    },
    async submitEvent() {
      this.submitLoading = true
      const user = new User()
      Object.assign(user, this.formData)
      user.roles = [user.roles]

      if (this.selectRow) {
        try {
          const response = await UserService.updateUser(user)
          this.showEdit = false
          Object.assign(this.selectRow, response.data.data)
        } catch {}
      } else {
        try {
          const response = await UserService.createUser(user)
          this.showEdit = false
          this.$refs.xTable.insert(response.data.data)
        } catch {}
      }
      this.submitLoading = false
    },
    async pageChange({ currentPage, pageSize }) {
      this.loading = true
      try {
        const response = await UserService.getUserBoard(currentPage, pageSize)
        this.tableData = response.data
      } catch {
        this.tableData = {}
      }
      this.loading = false
    },
    async loadRoles() {
      if (this.roleList.length > 0) {
        return
      }
      try {
        const response = await RoleService.get(1, 20)
        response.data.roles.forEach((element) => {
          this.roleList.push({
            label: element.name,
            value: element.id
          })
        })

        console.log(this.roleList)
      } catch {
        this.roleList = []
      }
    },
    checkRoles({ itemValue }) {
      if (itemValue.length === 0) {
        return new Error('请选择一个角色')
      }
    }
  }
}
</script>
