import api from '../services/api'
import Helper from './helper'

class RoleService {
  get(p, s) {
    return api.get(`/r?${Helper.getPageQuery(p, s)}`)
  }
}

export default new RoleService()
